.login{
    width: 50%;
    margin: auto;
    background-color: rgb(12,94,202);
    color: white;
    height: fit-content;
    border-radius: 20px;
    box-shadow: 10px 10px 5px 0px rgb(12,94,202);
    margin-top: 20px;
    margin-bottom: 20px;
    padding-bottom: 20px;
}

.login h1{
    font-size: 50px;
    margin: 5px;
    color: white;
}

.x{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-left: 90%;
    background-color: white;
    color:  rgb(12,94,202);
}

.x p{
    color: rgb(12,94,202);
    margin: auto;
    font-size: 30px;
}

form input{
    margin-bottom: 20px;
    width: 300px;
    height: 50px;
    border-radius: 20px;
    background-color: white;
    color: rgb(12,94,202);
}

.bt_login{
    width: 150px;
    height: 50px;
    background-color: white;
    color: rgb(12,94,202);
    border-radius: 20px;
}

.login form {
    margin-top: 20px;
}

.in{
    font-size: 30px;
    margin: auto;
}

.div_form{
    margin-left: 25%;
    text-align: left;
}

@media screen and (max-width: 780px){
    .login{
        width: 90%;
        margin: auto;
        background-color: rgb(12,94,202);
        color: white;
        height: fit-content;
        border-radius: 20px;
        box-shadow: 10px 10px 5px 0px rgb(12,94,202);
        margin-top: 20px;
        margin-bottom: 20px;
        padding-bottom: 20px;
    }
    
    .login h1{
        font-size: 50px;
        margin: 20px;
        color: white;
        text-align: center;;
    }
    
    .x{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-left: 85%;
        background-color: white;
        color:  rgb(12,94,202);
    }
    
    .x p{
        color: rgb(12,94,202);
        margin: auto;
        text-align: center;
        font-size: 40px;
    }
    
    form input{
        margin-bottom: 20px;
        width: 90%;
        height: 50px;
        border-radius: 20px;
        background-color: white;
        font-size: 25px;
        color: rgb(12,94,202);
    }
    
    .bt_login{
        width: 90%;
        height: 50px;
        background-color: white;
        color: rgb(12,94,202);
        border-radius: 20px;
    }
    
    .login form {
        margin-top: 20px;
    }
    
    .in{
        font-size: 30px;
        text-align: left;
    }
    
    .div_form{
        margin-left: 5%;
        text-align: left;
    }
}