*{
    margin: 0;
    padding: 0;
}

.header{
    background-color: rgb(12,94,202);
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: center;
}

.logo{
    font-family:cursive;
    font-size: 35px;
    color:white;
    margin-right: 5%;
    margin-left: 2px;
}

.menu-ul{
    display: flex;
    flex-direction: row;
    margin-left: 5%;
    list-style-type: none;
    margin-top: 10px;
}

.menu-li,.m{
    font-family: sans-serif;
    font-size: 25px;
    color: white;
    margin-left: 2%;
    width: 150px;
}

a{
    text-decoration: none;
    color: white;
}

.h{
    display: flex;
    flex-direction:row;
    text-align: center;
}

a:hover{
    color: gainsboro;
}