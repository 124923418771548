.content{
    display:flex;
    flex-direction: row;
}

.title{
    font-size: 50px;
    color:  rgb(12,94,202);
    text-align: center;
    margin-top: 15px;
}

.paragraph{
    width:30%;
    margin-right: 10%;
    font-size: 25px;
    margin-left: 15px;
    text-align: left;
}

.image_about{
    width:50%;
    display: flex;
    flex-direction: row;
}

.image_about img{
    width: 300px;
    height: 300px;
    object-fit: cover;
    border-radius: 10px;
    margin: 20px;
}