.Dashboard{
    display: flex;
    flex-direction: row;
}
.responsive {display:none;}
.side-menu{
    width:20%;
    min-height:100vh;
    background-color: rgb(12,94,202);
    color: white;
    text-align: left;
    padding-left: 3%;
}

ul{
    list-style-type: none;
}

.dash-menu-item{
    margin: 20px;
    font-size: 20px;
}
.dash-title{
    font-size: 40px;
}

a{text-decoration: none;
color: white;}

a:hover{
    color:gainsboro;
}

@media screen and (max-width:780px) {
    .Dashboard{
        display: block;
        width: 100%;
    }
    
    .responsive p{
        display: block;
        width: 100%;
        height: 20px;
    }

    .responsive{
        font-size: 20px;
        width: 100%;
        height:20px;
        display: block;
    }

    .responsive a{
        color: rgb(12,94,202);
        display: inline-block;
    }
    .responsive a:hover{
        color:rgb(12,94,202)
    }
    .side-menu{
       display:none;
    }
    
    ul{
        list-style-type: none;
    }
    
    .dash-menu-item{
        margin: 20px;
        font-size: 20px;
    }
    .dash-title{
        font-size: 40px;
    }
    
    a{text-decoration: none;
    color: white;}
    
    a:hover{
        color:gainsboro;
    }
    
}