h1{
    color: rgb(12,94,202);
    font-size: 40px;
    margin-bottom: 30px;
    margin-top: 30px;
}

.addd{
    display: flex;
    flex-direction: row;
    color:rgb(12,94,202);
    font-size: 30px;
    text-align: center;
    margin-left: 20%;
    margin-top: 20px;
    margin-bottom: 20px;
}

.add-button{
    width:200px;
    height: 50px;
    color: white;
    background-color: rgb(12,94,202);
    border-radius: 20px;
    border:0px;
    margin-top: 5px;
    font-size: 30px;
}
.form{
    font-size: 30px;
}
@media screen and (max-width:780px){
    h1{
        color: rgb(12,94,202);
        font-size: 30px;
        margin-bottom: 20px;
        text-align: left;
    }
    
    .add-button{
        width:100px;
        height: 30px;
        color: white;
        background-color: rgb(12,94,202);
        border-radius: 20px;
        border:0px;
        margin-top: 5px;
    }
    .addd{
        display: flex;
        flex-direction: row;
        color:rgb(12,94,202);
        font-size: 25px;
        text-align: center;
        margin-left: 2%;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .form{
        font-size: 20px;
    }
}