.work_card {
    background-color:white;
    color: black;
    border: none;
    width:200px;
    height: 200px;
    border-radius: 0%;
    margin: 20px;
    border-radius: 20px;
}

.work_container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

 h1{
    color: rgb(12,94,202);
    font-size: 40px;
    margin-bottom: 30px;
 }

.image{
    width:200px;
    margin:auto;
    height:200px;
    border-radius: 20px;
}

@media screen and (max-width:780px){
    .work_card {
        background-color:white;
        color: black;
        border: none;
        width:150px;
        height: 150px;
        border-radius: 0%;
        margin: 20px;
        border-radius: 20px;
    }

    .image{
        width:150px;
        height:150px;
        border-radius: 20px;
        object-fit:fill;
    }
}